import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { Text } from 'wix-ui-tpa/Text';
import { Tag, SIZE } from 'wix-ui-tpa/Tag';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { TPAComponentsContext } from 'wix-ui-tpa/TPAComponentsConfig';

import { Box, TextButton } from '@wix/social-groups-common/dist/src/components';
import BiLoggerContext from '@wix/social-groups-common/dist/src/context/bi-logger/BiContext';
import { BIUserEntry } from '@wix/social-groups-common/dist/src/context/bi-logger/types';

import { EFilterKeys } from '../../../../controllers/feed/FeedControllerProps';

import discussionStyles from '../Discussion.st.css';
import { classes } from './FeedTopicsWidget.st.css';
import { GroupContext } from '../../Context/GroupContext';

const FeedTopicsWidgetComponent: React.FC<InjectedTranslateProps> = ({ t }) => {
  const {
    feed: { feedTopics, applyFeedFilters },
    group: { groupId },
  } = React.useContext(GroupContext);
  const { mobile } = React.useContext(TPAComponentsContext);
  const MAX_TO_SHOW = React.useMemo(() => (mobile ? 4 : 10), [mobile]);

  const [collapsed, setCollapsed] = React.useState(true);
  const bi = React.useContext(BiLoggerContext);

  const topics = collapsed ? feedTopics.slice(0, MAX_TO_SHOW) : feedTopics;

  if (!feedTopics.length) {
    return null;
  }

  return (
    <Box article>
      <Text className={discussionStyles.classes.widgetHeader}>
        {t('groups-web.discussion.topics.widget.title')}
      </Text>
      <ul className={classes.list}>
        {topics.map((topic) => (
          <Tag
            key={topic.id}
            className={classes.tag}
            size={SIZE.small}
            onClick={() => applyFilter(topic)}
          >
            {`${topic.displayName} (${topic.count})`}
          </Tag>
        ))}
      </ul>
      {feedTopics.length > MAX_TO_SHOW ? (
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={toggleCollapsed}
        >
          {collapsed
            ? t('groups-web.discussion.topics.widget.more')
            : t('groups-web.discussion.topics.widget.less')}
        </TextButton>
      ) : null}
    </Box>
  );

  function toggleCollapsed() {
    setCollapsed(!collapsed);
  }

  function applyFilter(topic) {
    applyFeedFilters({
      [EFilterKeys.TOPICS]: topic.id,
    });
    bi.groupFeedTopicsSelectTopicInFeed({
      groupId,
      origin: 'feed_topics_section',
      userEntry: BIUserEntry.SITE,
    });
  }
};

export const FeedTopicsWidget = translate()(FeedTopicsWidgetComponent) as any;
