import React from 'react';

import { I18nextProvider } from 'react-i18next';
import { ExperimentsProvider } from '@wix/wix-experiments-react';
import { TPAComponentsProvider } from 'wix-ui-tpa/TPAComponentsConfig';
// COMMON
import {
  ConsoleLogger,
  LogLevel,
} from '@wix/social-groups-common/dist/src/loggers';
import { isMobileByFormFactor } from '@wix/social-groups-common/dist/src/utils';
import { GroupNotFound } from '@wix/social-groups-common/dist/src/components/GroupNotFound';
import {
  CommonAppSettingsProvider,
  CommonTPAComponentsProvider,
} from '@wix/social-groups-common/dist/src/tpaProviders';
import { MemberInvitesControllerProps } from '@wix/social-groups-common/dist/src/controllers/member-invites/MemberInvitesControllerProps';
import { TranslationDataProps } from '@wix/social-groups-common/dist/src/controllers/translations/TranslationDataProps';
import {
  BiContextProvider,
  BIUserEntry,
  tryToCallBi,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context/bi-logger';
import { ErrorLoggerProvider } from '@wix/social-groups-common/dist/src/context/error-logger';
import { CommonAppSettingsContextValue } from '@wix/social-groups-common/dist/src/context';
import {
  Environment,
  createBoundaryConfig,
} from '@wix/social-groups-common/dist/src/loggers/sentryConfig';
import { MemberInvitesProvider } from '@wix/social-groups-common/dist/src/context/member-invites';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { ProfilePrivacyDialog } from '@wix/social-groups-common/dist/src/components/PrivacyDialog/ProfilePrivacyDialog';
import { OOIHost } from '@wix/social-groups-common/dist/src/components/OOIComponent/OOIHost';
import { MembershipQuestionsModal } from '@wix/social-groups-common/dist/src/components/MembershipQuestionsModal/MembershipQuestionsModal';
// TODO: dynamic?
import { AppToastsProvider } from '@wix/social-groups-common/dist/src/components/AppToats';
import { WithAppToastsProps } from '@wix/social-groups-common/dist/src/types';
// API
import {
  isProfilePublic,
  Anonymous,
} from '@wix/social-groups-api/dist/src/model/Member/Member';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { isJoined } from '@wix/social-groups-api/dist/src/model/Member/MemberRole';
import { GroupWrapper } from '@wix/social-groups-api/dist/src/model/Group/GroupWrapper';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import i18n from '../../config/i18n';
import {
  FeedControllerProps,
  GroupControllerProps,
  MembersControllerProps,
  MainControllerProps,
  ErrorHandlerControllerProps,
  ActivityControllerProps,
  EventsControllerProps,
  MediaControllerProps,
} from '../../controllers/types';
import { Group } from './Group';

import { LoggersContext } from './Context/logger';
import { ActivityContext } from './Context/Activity';
import { EventsContext } from './Context/EventsContext';
import {
  NotificationSettings,
  NotificationSettingsContext,
} from './Context/NotificationSettings';
import { ErrorHandlerContext } from './Context/ErrorHandler/ErrorHandlerContext';
import { LeaveGroupDialog } from './Dialogs/LeaveGroupDialog';
import { WithdrawJoinRequestDialog } from './Dialogs/WithdrawJoinRequest';
import { JoinDialog } from './Dialogs/JoinDialog';
import { DATA_HOOKS } from './dataHooks';
import { ProfileContext } from './Context/Profile/Profile';
import { groupNotFound } from '../../controllers/errorHandler/errorEvent';
import { ErrorOrigin } from '../../controllers/errorHandler/IErrorEvent';
import { WixCommentsTranslationsProvider } from '@wix/comments-ooi-translations-provider';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { Host } from '@wix/comments-ooi-client/dist/types/common/platform-types';
import { ICommentsProps } from '../../controllers/comments/ICommentsProps';
import { Tab } from '@wix/social-groups-common/dist/src/controllers';
import { AppData } from './Context/AppData/IAppData';
import { WithAppSettingsProps } from './Context/withAppSettings';
import { AppSettingsContext } from './Context/AppSettingsContext';
import { AppDataContext } from './Context/AppData/AppData';
import { GroupContextProvider } from './Context/GroupContext';
import { GroupActionsProvider } from './Context/GroupActionsContext';
import { SiteMembersContext } from './Context/SiteMembers';

enum OpenedDialog {
  WITHDRAW_JOIN_REQUEST = 'cancel join request',
  LEAVE_GROUP = 'leave group',
  JOIN_DIALOG = 'join dialog',
  PRIVACY_DIALOG = 'change profile privacy',
  QUESTIONS = 'questions',
}

export interface GroupWidgetState {
  error: null | string;
  openDialog: OpenedDialog;
}

export interface GroupWidgetProps
  extends GroupControllerProps,
    FeedControllerProps,
    MembersControllerProps,
    MemberInvitesControllerProps,
    WithAppToastsProps,
    MainControllerProps,
    ActivityControllerProps,
    NotificationSettings,
    AppData,
    WithAppSettingsProps,
    WithBiLoggerProps,
    TranslationDataProps,
    ErrorHandlerControllerProps,
    EventsControllerProps,
    MediaControllerProps,
    ICommentsProps {}

export class GroupWidget extends React.Component<
  GroupWidgetProps,
  GroupWidgetState
> {
  static displayName = 'GroupWidget';
  readonly state: GroupWidgetState = {
    error: null,
    openDialog: null,
  };

  constructor(props: GroupWidgetProps, context: any) {
    super(props, context);
    if (typeof window !== 'undefined') {
      ConsoleLogger.setModeFromLocation();
      window.scrollTo(0, 0);
    }
  }

  componentDidMount(): void {
    this.setupLocale(this.props.locale);
    this.reportAppLoaded();
  }

  componentDidUpdate(
    prevProps: Readonly<GroupWidgetProps>,
    prevState: Readonly<GroupWidgetState>,
  ) {
    if (!prevProps.reportAppLoaded && this.props.reportAppLoaded) {
      this.reportAppLoaded();
    }
  }

  private reportAppLoaded() {
    if (!this.props.reportAppLoaded) {
      return;
    }
    try {
      this.props.host.registerToComponentDidLayout(this.props.reportAppLoaded);
    } catch (e) {
      console.log('Error in GroupWidget.reportAppLoaded');
    }
  }

  UNSAFE_componentWillReceiveProps(
    nextProps: Readonly<GroupWidgetProps>,
    nextContext: any,
  ): void {
    if (nextProps.locale !== this.props.locale) {
      this.setupLocale(nextProps.locale);
    }
    if (nextProps.logLevel !== this.props.logLevel) {
      ConsoleLogger.mode = nextProps.logLevel;
    }
  }

  private setupLocale(lcl) {
    // TODO: make `moment/locale/${lcl}` work
    // + keep in mind that webpack's dynamic `import` does not work for SSR
  }

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  static getDerivedStateFromError(error) {
    return {
      error,
    };
  }

  static defaultProps: Partial<GroupWidgetProps> = {
    isLoggedIn: false,
    promptLogin() {},
    actions: {} as any,
    activeTab: Tab.DISCUSSION,
    currentMember: Anonymous,
    experiments: {},
    group: GroupWrapper.createEmpty(),
    loading: false,
    locale: '',
    logLevel: LogLevel.NONE,
    memberInvitesLink: '',
    members: [],
    membersActions: {} as any,
    siteMembers: [],
    siteMembersMap: {},
    style: {} as any,
    uploadedRegistry: [],
    externalVideosMetadataRegistry: [],
    changeTab(): void {},
    commentFeedItem(): void {},
    createFeedItem(): void {},
    updateFeedItem(): void {},
    deleteFeedItem(): void {},
    pinFeedItem(): void {},
    unpinFeedItem(): void {},
    followFeedItem(): void {},
    unfollowFeedItem(): void {},
    updateProgress: UpdateProgress.STALE,
  };

  render() {
    const {
      appSettings,
      experiments,
      host,
      language,
      translation,
      biLogger,
      isRTL,
    } = this.props;

    const { style: styles, formFactor } = host;

    const isMobile = isMobileByFormFactor(formFactor);

    if (this.state.error !== null) {
      return (
        <>
          <h1>Ooops!</h1>
          <div>{this.state.error}</div>
        </>
      );
    }

    const style = this.getSiteRootWidth() as any;

    const dir = isRTL ? 'rtl' : null;

    const commonAppSettings: CommonAppSettingsContextValue = {
      buttonType: appSettings?.buttonType,
      styles,
    };

    return (
      <div data-hook={DATA_HOOKS.root} style={style} dir={dir}>
        <I18nextProvider i18n={i18n({ language, translation })}>
          <ExperimentsProvider options={{ experiments }}>
            <TPAComponentsProvider value={{ mobile: isMobile, rtl: isRTL }}>
              <CommonTPAComponentsProvider
                value={{ mobile: isMobile, rtl: isRTL }}
              >
                <AppSettingsContext.Provider
                  value={{
                    ...appSettings,
                    styles,
                  }}
                >
                  <CommonAppSettingsProvider value={commonAppSettings}>
                    <LoggersContext.Provider value={{ logger: ConsoleLogger }}>
                      <BiContextProvider value={biLogger}>
                        <ErrorLoggerProvider
                          LazySentry={this.props.host.LazySentry}
                          sentryConfig={createBoundaryConfig(
                            Environment.VIEWER,
                          )}
                        >
                          {this.renderGroup()}
                        </ErrorLoggerProvider>
                      </BiContextProvider>
                    </LoggersContext.Provider>
                  </CommonAppSettingsProvider>
                </AppSettingsContext.Provider>
              </CommonTPAComponentsProvider>
            </TPAComponentsProvider>
          </ExperimentsProvider>
        </I18nextProvider>
      </div>
    );
  }

  private renderGroup() {
    const { errorHandlers, errorEvents, host, ...rest } = this.props;
    if (errorEvents && groupNotFound(errorEvents[ErrorOrigin.Group])) {
      const height = host.dimensions.height ? host.dimensions.height : 'auto';
      return (
        <div style={{ height }}>
          <GroupNotFound
            goToGroupList={() =>
              errorHandlers.actionByError(errorEvents[ErrorOrigin.Group])
            }
          />
        </div>
      );
    }
    const {
      actions,
      style,
      isRTL,
      isEditor,
      viewMode,
      cursor,
      contextToken,
      fetchMore,
      activeTab,
      activeButton,
      activities,
      activityActions,
      apps,
      changeTab,
      commentFeedItem,
      createFeedItem,
      currentMember,
      pendingMembers,
      currentSiteMember,
      deleteFeedItem,
      feedItemId,
      feedItems,
      feedTopics,
      feedFilters,
      mediaItems,
      followFeedItem,
      followingMembers,
      group,
      rules,
      isFeedItemCreating,
      isLoggedIn,
      loadMemberInvitesLink,
      feedLoading,
      memberInvitesLink,
      members,
      membersActions,
      membersQueryResponse,
      newMembers,
      notificationActions,
      notificationSettings,
      pinFeedItem,
      promptLogin,
      reactFeedItem,
      siteMembers,
      siteMembersMap,
      nonGroupMembersCount,
      toasts,
      createPostTopic,
      applyFeedFilters,
      unfollowFeedItem,
      unpinFeedItem,
      unreactFeedItem,
      updateFeedItem,
      updateProgress,
      uploadedRegistry,
      externalVideosMetadataRegistry,
      appDefinitionId,
      instance,
      membersUpdate,
      questions,
      questionsAnswers,
      language,
      locale,
      instanceId,
      requestState,
      eventsContext,
      mediaContext,
      siteNavigation,
      navigateToLink,
      isGroupOwner,
      tabsUrls,
      ...commentsProps
    } = rest;
    return (
      <ErrorHandlerContext.Provider value={{ errorEvents, errorHandlers }}>
        <AppDataContext.Provider
          value={{
            appDefinitionId,
            instance,
            style,
            isRTL,
            host,
            language,
            locale,
            instanceId,
            isEditor,
            viewMode,
            activeButton,
          }}
        >
          <GroupContextProvider
            value={{
              group,
              members,
              rules,
              currentMember,
              uploadedRegistry,
              externalVideosMetadataRegistry,
              media: mediaContext,
              feed: {
                createPostTopic,
                applyFeedFilters,
                feedLoading,
                contextToken,
                cursor,
                fetchMore,
                isFeedItemCreating,
                feedItems,
                feedTopics,
                feedFilters,
                mediaItems,
                deleteFeedItem,
                createFeedItem,
                updateFeedItem,
                commentFeedItem,
                pinFeedItem,
                unpinFeedItem,
                followFeedItem,
                unfollowFeedItem,
                reactFeedItem,
                unreactFeedItem,
              },
              promptLogin,
              isLoggedIn,
              updateProgress,
              apps,
              questions,
              isGroupOwner,
            }}
          >
            <GroupActionsProvider
              value={{
                ...actions,
                changeMembership: this.changeMembership,
                openJoinDialog: this.openJoinDialog,
              }}
            >
              <SiteMembersContext.Provider
                value={{
                  currentSiteMember,
                  followingMembers,
                  membersActions,
                  membersQueryResponse,
                  newMembers,
                  pendingMembers,
                  siteMembers,
                  siteMembersMap,
                  membersUpdate,
                  nonGroupMembersCount,
                  questionsAnswers,
                  requestState,
                }}
              >
                <AppToastsProvider value={toasts}>
                  <ActivityContext.Provider
                    value={{ activities, activityActions }}
                  >
                    <NotificationSettingsContext.Provider
                      value={{
                        notificationActions,
                        notificationSettings,
                      }}
                    >
                      <MemberInvitesProvider
                        value={{ memberInvitesLink, loadMemberInvitesLink }}
                      >
                        <ProfileContext.Provider
                          value={{
                            isProfilePrivate: this.isProfilePrivate(),
                            openProfileDialog: this.openProfileDialog,
                            isProfileUpdating: this.isProfileUpdating(),
                          }}
                        >
                          <EventsContext.Provider value={eventsContext}>
                            <WixCommentsApiProvider
                              host={host as Host}
                              {...commentsProps}
                            >
                              <WixCommentsTranslationsProvider>
                                <Group
                                  feedItemId={feedItemId}
                                  activeTab={activeTab}
                                  changeTab={changeTab}
                                  canSeeGroup={canSeeGroup(group)}
                                  ready={!!group.groupId}
                                  apps={apps}
                                  group={group}
                                  siteNavigation={siteNavigation}
                                  navigateToLink={navigateToLink}
                                  tabsUrls={tabsUrls}
                                  isRTL={isRTL}
                                  isEditor={isEditor}
                                />
                              </WixCommentsTranslationsProvider>
                            </WixCommentsApiProvider>
                          </EventsContext.Provider>
                        </ProfileContext.Provider>
                        {this.renderWithdrawJoinRequestDialog()}
                        {this.renderLeaveDialog()}
                        {this.renderJoinDialog()}
                        {this.renderPrivacyDialog()}
                        {this.renderQuestions()}
                      </MemberInvitesProvider>
                    </NotificationSettingsContext.Provider>
                  </ActivityContext.Provider>
                </AppToastsProvider>
              </SiteMembersContext.Provider>
            </GroupActionsProvider>
          </GroupContextProvider>
        </AppDataContext.Provider>
      </ErrorHandlerContext.Provider>
    );
  }

  private isProfileUpdating() {
    const { membersUpdate, currentSiteMember } = this.props;
    return (
      membersUpdate &&
      currentSiteMember &&
      membersUpdate.includes(currentSiteMember.id)
    );
  }

  private renderLeaveDialog() {
    const { group } = this.props;
    const groupWrapper = new GroupWrapper(group);

    return (
      <LeaveGroupDialog
        isOpen={this.isOpenDialog(OpenedDialog.LEAVE_GROUP)}
        onRequestClose={this.closeDialog}
        groupId={group.groupId}
        groupTitle={groupWrapper.getTitle()}
        onLeaveGroup={this.handleLeaveGroupConfirm}
      />
    );
  }

  private renderWithdrawJoinRequestDialog() {
    return (
      <WithdrawJoinRequestDialog
        isOpen={this.isOpenDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST)}
        onRequestClose={this.closeDialog}
        onWithdrawJoinRequest={this.handleWithdrawJoinRequest}
      />
    );
  }

  private renderJoinDialog() {
    return (
      <JoinDialog
        isOpen={this.isJoinDialogOpen()}
        onRequestClose={this.refuseAndCloseDialog}
      />
    );
  }

  refuseAndCloseDialog = () => {
    if (this.props.refuseToJoin) {
      this.props.refuseToJoin();
    }
    this.closeDialog();
  };

  private isJoinDialogOpen() {
    if (this.props.requestToJoin && !this.isProfilePrivate()) {
      return true;
    }
    return this.isOpenDialog(OpenedDialog.JOIN_DIALOG);
  }

  private renderQuestions() {
    const { group, questions, actions, host } = this.props;
    const groupWrapper = new GroupWrapper(group);
    return (
      <MembershipQuestionsModal
        isOpen={!!questions && this.state.openDialog === OpenedDialog.QUESTIONS}
        onRequestClose={this.closeQuestions}
        questions={questions}
        groupName={groupWrapper.getTitle()}
        groupId={group.groupId}
        onSubmit={(answers) => actions.submitAnswers(group, answers)}
        className={host && host.id}
      />
    );
  }

  private readonly closeQuestions = () => {
    const { actions } = this.props;
    actions.rejectAnswers();
    this.closeDialog();
  };

  private renderPrivacyDialog() {
    return (
      this.isProfilePrivate() && (
        <ProfilePrivacyDialog
          onRequestClose={this.refuseAndCloseDialog}
          onChangeProfile={this.makeProfilePublic}
          isOpen={this.isPrivacyDialogOpen()}
        />
      )
    );
  }

  private isPrivacyDialogOpen() {
    if (this.props.requestToJoin && this.isProfilePrivate()) {
      return true;
    }
    return this.isOpenDialog(OpenedDialog.PRIVACY_DIALOG);
  }

  private readonly changeMembership = (biOrigin: string) => {
    const { group } = this.props;

    switch (group.relationship) {
      case ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP:
      case ApiTypes.v1.RelationshipWithGroup.NONE:
        return this.handleJoinGroup(biOrigin);
      case ApiTypes.v1.RelationshipWithGroup.JOINED:
        return this.handleLeaveGroupClick(biOrigin);
      case ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL:
        return this.openWithdrawJoinRequestDialog();
      default:
        console.warn('Unknown group relationship');
    }
  };

  private isProfilePrivate(): boolean {
    const { currentSiteMember } = this.props;
    return currentSiteMember && !isProfilePublic(currentSiteMember);
  }

  private readonly makeProfilePublic = () => {
    try {
      this.props.membersActions.makeProfilePublic(
        this.props.currentSiteMember.id,
      );
    } catch (e) {
      console.log('Failed to make profile public');
    }
  };
  private readonly openProfileDialog = () =>
    this.openDialog(OpenedDialog.PRIVACY_DIALOG);
  private readonly openJoinDialog = () => {
    if (this.isProfilePrivate()) {
      return this.openProfileDialog();
    }
    return this.openDialog(OpenedDialog.JOIN_DIALOG);
  };
  private readonly openLeaveDialog = () =>
    this.openDialog(OpenedDialog.LEAVE_GROUP);
  private readonly openWithdrawJoinRequestDialog = () =>
    this.openDialog(OpenedDialog.WITHDRAW_JOIN_REQUEST);
  private readonly closeDialog = () => this.setState({ openDialog: null });

  private readonly openDialog = (dialog) => {
    this.setState({ openDialog: dialog });
  };

  private isOpenDialog(dialog: OpenedDialog): boolean {
    return this.state.openDialog === dialog;
  }

  private readonly handleLeaveGroupConfirm = () => {
    this.props.actions.leaveGroup();
    this.closeDialog();
  };

  private readonly handleWithdrawJoinRequest = () => {
    this.props.actions.withdrawJoinRequest();
    this.closeDialog();
  };

  private readonly handleJoinGroup = (biOrigin: string) => {
    const { group, biLogger, actions } = this.props;
    if (!isJoined(group)) {
      tryToCallBi(async () => {
        await biLogger.groupsRequestJoinAGroup({
          approvalStatus: group.approvalStatus,
          groupId: group.groupId,
          origin: biOrigin,
          type: group.settings.privacyLevel,
        } as any);
      });
    }
    actions.joinGroup();
    this.openDialog(OpenedDialog.QUESTIONS);
  };

  private readonly handleLeaveGroupClick = (biOrigin: string) => {
    const { group, biLogger } = this.props;
    tryToCallBi(async () => {
      await biLogger.groupActionClick({
        action: 'leave',
        group_id: group.groupId,
        origin: biOrigin,
        userEntry: BIUserEntry.SITE,
      });
    });
    this.openLeaveDialog();
  };

  private getSiteRootWidth() {
    try {
      const host = new OOIHost(this.props.host);
      if (host.isPreview() && host.isMobile()) {
        const { width, left } = host.getDimensions();
        const w = width + left * 2;

        if (w) {
          return { '--siteRootWidth': `${w}px` };
        }
      }
    } catch (e) {}
    return;
  }
}
