import React from 'react';

import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { Breadcrumbs } from '@wix/social-groups-common/dist/src/components/Breadcrumbs';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { EFilterKeys } from '../../../../controllers/feed/FeedControllerProps';
import { ArrowBackIcon } from '../../../icons/ArrowBackIcon';

import { getBreadcrumbMap } from './helpers';
import { st, classes } from './Navigation.st.css';
import { GroupContext } from '../../Context/GroupContext';

export const NavigationComponent: React.FC<InjectedTranslateProps> = ({
  t,
}) => {
  const { feed } = React.useContext(GroupContext);
  const { feedFilters: filters, feedTopics } = feed;
  const labels = getBreadcrumbMap(t);

  if (!hasFilters(filters)) {
    return null;
  }

  return (
    <div className={st(classes.root)}>
      {filters.feedItemId ? (
        <TextButton
          onClick={clearFilters}
          prefixIcon={<ArrowBackIcon width="24px" height="24px" />}
          priority={TEXT_BUTTON_PRIORITY.secondary}
          className={classes.back}
        >
          {t('groups-web.discussion.post-not-found.back')}
        </TextButton>
      ) : (
        <Breadcrumbs
          items={[
            {
              label: t('groups-web.discussion.title'),
              action: clearFilters,
            },
            {
              label: labels[EFilterKeys.TOPICS](feedTopics, filters),
            },
          ]}
        />
      )}
    </div>
  );

  function clearFilters() {
    feed.applyFeedFilters();
  }
};

function hasFilters(filters) {
  return Object.values(filters).some((value) => !!value);
}

export const Navigation = translate()(NavigationComponent) as any;
