import {
  WixRicosEditorProps,
  WixRicosViewerProps,
} from '@wix/ricos-common/dist/types/WixRicosTypes';

export enum PRESETS {
  ABOUT = 'about',
  POST = 'post',
  CUSTOM_TAB = 'custom_tab',
}

export interface RichContentProps {
  preset?: PRESETS;
  className?: string;
}

export interface RichContentViewerProps
  extends WixRicosViewerProps,
    RichContentProps {
  handleUserValidationRequest?(resolve: Function, reject: Function);
  disablePreview?: boolean;
  usage: 'About' | 'FeedItem';
}

export interface RichContentEditorProps
  extends WixRicosEditorProps,
    RichContentProps {
  autoFocus?: boolean;
  usage: 'About' | 'NewPostModal' | 'CustomTab';

  initEditorEvents?(editorEvents, events);
  forwardRef?: any;
}
// TODO: move to common - we have 2 identical enums
export enum PluginTypes {
  Giphy = 'wix-draft-plugin-giphy',
  Image = 'wix-draft-plugin-image',
  Poll = 'wix-draft-plugin-poll',
  Gallery = 'wix-draft-plugin-gallery',
  FileUpload = 'wix-draft-plugin-file-upload',
  Video = 'wix-draft-plugin-video',
  ExternalMentions = 'wix-draft-plugin-external-mentions',
  Mention = 'mention',
  LinkPreview = 'wix-draft-plugin-link-preview',
  Emoji = 'wix-draft-plugin-emoji',
  ExternalLink = 'wix-draft-plugin-external-link',
  Link = 'LINK',
  VerticalEmbed = 'wix-draft-plugin-vertical-embed',
  Divider = 'wix-draft-plugin-divider',
  Headings = 'wix-rich-content-plugin-headings',
  HTML = 'wix-draft-plugin-html',
  History = 'wix-rich-content-undo-redo',
  LinkButton = 'wix-draft-plugin-link-button',
  ActionButton = 'wix-draft-plugin-action-button',
  TextColor = 'wix-rich-content-text-color',
  TextHighlight = 'wix-rich-content-text-highlight',
  Map = 'wix-draft-plugin-map',
  SoundCloud = 'wix-draft-plugin-sound-cloud',
}

export const VerticalEmbedProviders = {
  EVENT: 'event',
  BOOKING: 'booking',
  PRODUCT: 'product',
};

export const customTabPluginTypes = [
  PluginTypes.LinkButton,
  PluginTypes.VerticalEmbed,
  PluginTypes.Divider,
  PluginTypes.HTML,
  PluginTypes.TextColor,
  PluginTypes.TextHighlight,
];
