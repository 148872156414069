import React from 'react';
import { GroupContext } from '../../Group/Context/GroupContext';
import { SiteMembersContext } from '../../Group/Context/SiteMembers';

export function useMentions() {
  const [query, setQuery] = React.useState<string>();
  const [resolve, setResolve] = React.useState<Function>();

  const { group } = React.useContext(GroupContext);
  const { membersActions, membersQueryResponse } = React.useContext(
    SiteMembersContext,
  );

  React.useEffect(() => {
    if (!resolve) {
      return;
    }

    if (query === membersQueryResponse.query) {
      resolve(
        membersQueryResponse.members.map((member) => ({
          name: member.name.nick,
          avatar: member.imageUrl,
          id: member.siteMemberId,
        })),
      );
    }
  }, [membersQueryResponse.query, query, resolve]);

  return {
    getMentions,
    onMentionClick,
  };

  function onMentionClick(mention) {
    membersActions.openUserProfile(mention.id);
  }

  function getMentions(_query) {
    return new Promise((_resolve) => {
      setResolve(() => _resolve);
      setQuery(_query);
      membersActions.filterMembers(group, _query);
    });
  }
}
