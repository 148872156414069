import React from 'react';
import classnames from 'classnames';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { Box, BoxProps } from '@wix/social-groups-common/dist/src/components/Box';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import classname from 'classnames';
import { st, classes } from './EmptyState.st.css';

export interface EmptyStateProps {
  title?: string;
  transparent?: boolean;
  content: React.ReactNode;
  className?: string;
  button?:
    | React.ReactElement
    | {
        label: string;
        onClick(): void;
      };
  dataHook?: string;
  boxProps?: BoxProps;
}

const EmptyStateComponent: React.FC<EmptyStateProps & TPAComponentsConfig> = (
  props,
) => {
  const { title, content, button, dataHook, boxProps, transparent } = props;

  const renderButton = () => {
    return React.isValidElement(button) ? (
      button
    ) : (
      <Button priority={PRIORITY.primary} onClick={button.onClick}>
        {button.label}
      </Button>
    );
  };

  return (
    <Box
      className={classnames(st(classes.root, {}, props.className), {
        [classes.transparent]: transparent
      })}
      data-hook={dataHook}
      {...boxProps}
    >
      {title && (
        <Text typography={TYPOGRAPHY.smallTitle} className={classes.text}>
          {title}
        </Text>
      )}
      <Text
        typography={TYPOGRAPHY.listText}
        className={classname(classes.content, classes.text)}
        tagName="p"
      >
        {content}
      </Text>
      {button ? renderButton() : null}
    </Box>
  );
};

export const EmptyState = withTPAConfig(
  EmptyStateComponent,
) as React.ComponentType<EmptyStateProps>;
