import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Block,
  BlockAlign,
  Button,
  Loader,
  ModalV2,
  Search,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { Text } from 'wix-ui-tpa/Text';
import { Checkbox } from 'wix-ui-tpa/Checkbox';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import {
  isGroupSecret,
  isPendingGroup,
} from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { memberWrapper } from '@wix/social-groups-api/dist/src/model/Member/Member';
import { MembersPublicApi } from '@wix/social-groups-api/dist/src/services/membersApi/MembersPublicApi';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { isValidEmail } from '@wix/social-groups-common/dist/src/utils';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../Context/withSiteMembers';
import {
  InviteMembersProps,
  withInviteMembers,
} from '../../Context/InviteMembersProps';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';

import { getMembersLabel } from '../../../MembersLabel/MembersLabel';
import { EmptyState } from '../../EmptyState';
import { MemberCard } from '../../MemberCard/MemberCard';
import { LetterIcon } from '../../../icons/LetterIcon';
import { CanNotAddMembersModal } from '../CanNotAddMemebersModal';

import { st, classes } from './AddMembersModal.st.css';
import { PendingRequest } from '../../loaders/PendingRequest';
import { RequestState } from '../../../../controllers/RequestState';
import {
  withAppData,
  WithAppDataProps,
} from '../../Context/AppData/withAppData';

const INVITE_SENT_TIMEOUT = 1000;

export interface AddMembersModalProps {
  isOpen: boolean;
  group: ApiTypes.v1.GroupResponse;
  currentMember: ApiTypes.v1.GroupMemberResponse;

  handleClose();
}

type AddMembersProps = AddMembersModalProps &
  InjectedTranslateProps &
  WithSiteMembers &
  InviteMembersProps &
  WithAppDataProps &
  WithBiLoggerProps &
  WithTpaComponentsConfigProps &
  InjectedExperimentsProps;

interface AddMembersModalState {
  searchQuery: string;
  invitesMap: { [email: string]: number };

  selectAll: boolean;
  excludedIds: Set<string>;
  selectedIds: Set<string>;
}
export const ADD_MEMBERS_HOOK = 'add-members';

class AddMembersModalComponent extends React.Component<
  AddMembersProps,
  AddMembersModalState
> {
  static defaultProps = {
    siteMembers: [],
  };

  state = {
    searchQuery: '',
    invitesMap: {},
    selectAll: null,
    excludedIds: new Set<string>(),
    selectedIds: new Set<string>(),
  };

  componentDidUpdate(prevProps: Readonly<AddMembersProps>) {
    // after closed
    if (prevProps.isOpen && !this.props.isOpen) {
      this.setState({
        searchQuery: '',
        selectAll: false,
        excludedIds: new Set(),
        selectedIds: new Set(),
      });
    }
    // after opened
    if (!prevProps.isOpen && this.props.isOpen) {
      this.props.setNonGroupMembers();
    }

    // some members successfully added
    if (prevProps.nonGroupMembersCount !== this.props.nonGroupMembersCount) {
      this.props.handleClose();
    }
  }

  setSearchQuery = (value: string) => {
    this.setState({ searchQuery: value });
  };

  render() {
    const { t, isOpen, handleClose, group, mobile } = this.props;
    const { searchQuery } = this.state;
    const { key, value } = getMembersLabel(group, t);
    const title = t(`${key}.add-widget.title`, { membersLabel: value });
    const nonGroupMembers = this.getNonGroupMembers();
    const hasNonGroupMembers = !!nonGroupMembers.length;

    const canShowSearch = isGroupSecret(group)
      ? hasNonGroupMembers || searchQuery
      : true;

    if (isPendingGroup(group)) {
      return <CanNotAddMembersModal isOpen={isOpen} onClose={handleClose} />;
    }

    return (
      <ModalV2 isOpen={isOpen} onRequestClose={handleClose}>
        <ModalV2.Title>{title}</ModalV2.Title>
        <div className={classes.searchWrapper}>
          {canShowSearch ? (
            <Search
              onChange={this.setSearchQuery}
              withBorder={!mobile}
              fullWidth={true}
              forceOpen={true}
              placeholder={this.getSearchPlaceholder()}
              withCloseButton={!!searchQuery}
            />
          ) : null}
        </div>
        <ModalV2.Content>{this.renderMembers(nonGroupMembers)}</ModalV2.Content>
        {nonGroupMembers.length ? this.renderAddMembers() : null}
      </ModalV2>
    );
  }

  getSearchPlaceholder = () => {
    const { t, group, nonGroupMembersCount } = this.props;
    const isSecret = isGroupSecret(group);

    let key = '';
    if (isSecret) {
      key = 'groups-web.secret-group.add.members.search.placeholder';
    } else {
      key = nonGroupMembersCount
        ? 'groups-web.add.members.search.placeholder'
        : 'groups-web.add.members.search.no-site-members.placeholder';
    }
    return t(key, { count: nonGroupMembersCount });
  };

  handleMemberSelect = (memberId: string, selected: boolean) => {
    const { selectedIds, excludedIds, selectAll } = this.state;
    const { nonGroupMembersCount } = this.props;

    const newSelectedIds = new Set(selectedIds);
    const newExcludedIds = new Set(excludedIds);
    if (selected) {
      newSelectedIds.add(memberId);
      newExcludedIds.delete(memberId);
    } else {
      newSelectedIds.delete(memberId);
      newExcludedIds.add(memberId);
    }

    const allExcluded = nonGroupMembersCount === newExcludedIds.size;

    this.setState({
      selectedIds: newSelectedIds,
      excludedIds: newExcludedIds,
      selectAll: allExcluded ? false : selectAll,
    });
  };

  addMembers = () => {
    const { biLogger, group, addAllNonGroupMembers, addMembers } = this.props;
    const { selectAll, selectedIds, excludedIds } = this.state;

    tryToCallBi(async () => {
      await biLogger.groupsAddMemberClicked({
        origin: 'modal_plus_add_btn',
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
      });
    });

    if (selectAll !== null) {
      tryToCallBi(async () => {
        await biLogger.groupsSelectAllMembersClicked({
          origin: 'members_list_scr',
          groupId: group.groupId,
          userEntry: BIUserEntry.SITE,
          action: selectAll ? 'select' : 'unselect',
        });
      });
    }

    if (selectAll) {
      addAllNonGroupMembers(group, Array.from(excludedIds));
    } else {
      addMembers(group, Array.from(selectedIds));
    }
  };

  inviteMemberByEmail = () => {
    const { inviteMembersByEmail, biLogger } = this.props;
    const { searchQuery } = this.state;
    tryToCallBi(async () => {
      await biLogger.inviteSent({
        group_id: this.props.group.groupId,
        site_member_id: null,
        contact: searchQuery,
        origin: 'dialog_scr_invite_btn',
      } as any);
    });
    this.addEmailToInvitesSent(searchQuery.trim());
    inviteMembersByEmail([searchQuery.trim()]);
  };

  private addEmailToInvitesSent(email: string) {
    const { invitesMap } = this.state;
    const timeout = () => {
      return setTimeout(() => {
        this.removeEmailFromInvitesSent(email);
      }, INVITE_SENT_TIMEOUT);
    };
    this.setState({ invitesMap: { ...invitesMap, [email]: timeout() } });
  }

  private removeEmailFromInvitesSent(email: string) {
    const { invitesMap } = this.state;
    this.setState({ invitesMap: { ...invitesMap, [email]: null } });
  }

  private readonly handleSelectAllChanges = () => {
    this.setState({
      selectAll: !this.state.selectAll,
      excludedIds: new Set(),
      selectedIds: new Set(),
    });
  };

  private readonly isMemberSelected = (id: string) => {
    const { selectAll, excludedIds, selectedIds } = this.state;
    return (selectAll && !excludedIds.has(id)) || selectedIds.has(id);
  };

  private readonly getSelectedMembersCount = (): number => {
    const { nonGroupMembersCount } = this.props;
    const { selectAll, excludedIds, selectedIds } = this.state;

    if (selectAll) {
      return nonGroupMembersCount - excludedIds.size;
    }
    return selectedIds.size;
  };
  private renderAddMembers() {
    const { t, nonGroupMembersCount } = this.props;
    const { searchQuery } = this.state;
    const selectedCount = this.getSelectedMembersCount();

    const hasSelectedMembers = !!selectedCount;
    return (
      <Block
        justify={BlockAlign.start}
        align={BlockAlign.center}
        className={st(classes.selectAll, {
          withButton: hasSelectedMembers,
          notEmptySearch: !!searchQuery,
        })}
      >
        {this.renderSelectAll()}
        <Text className={classes.selectedCount}>
          {searchQuery || hasSelectedMembers
            ? t('group-web.add.members.selected', { count: selectedCount })
            : t('group-web.add.members.select-all', {
                count: nonGroupMembersCount,
              })}
        </Text>
        {selectedCount ? (
          <Button
            disabled={this.areMembersUpdating()}
            prefixIcon={this.areMembersUpdating() && <Loader />}
            onClick={this.addMembers}
            data-hook={ADD_MEMBERS_HOOK}
          >
            {t('groups-web.add')}
          </Button>
        ) : null}
      </Block>
    );
  }

  private renderSelectAll() {
    const { selectAll, searchQuery } = this.state;
    const { nonGroupMembersCount } = this.props;
    const selectedCount = this.getSelectedMembersCount();
    const hasSelectedMembers = !!selectedCount;
    const indeterminate =
      hasSelectedMembers && nonGroupMembersCount !== selectedCount; // ??
    return !searchQuery ? (
      <Checkbox
        checked={!indeterminate && selectAll}
        indeterminate={indeterminate}
        onChange={this.handleSelectAllChanges}
      />
    ) : null;
  }

  private areMembersUpdating() {
    const { membersUpdate } = this.props;
    return membersUpdate && !!membersUpdate.length;
  }

  private renderMembers(siteMembers: ApiTypes.v1.SiteMemberProfileResponse[]) {
    const { group } = this.props;

    if (!siteMembers.length) {
      return isValidEmail(this.state.searchQuery.trim()) &&
        !isGroupSecret(group)
        ? this.renderInvites()
        : this.renderNoSiteMembersState();
    }

    return (
      this.renderLoader('addAllNonGroupMembersToGroup') || (
        <>
          {this.renderMemberCards(siteMembers)}
          {this.renderLoader('queryNonGroupMembers')}
        </>
      )
    );
  }

  private renderMemberCards(
    siteMembers: ApiTypes.v1.SiteMemberProfileResponse[],
  ) {
    const { t, mobile, membersUpdate } = this.props;
    return siteMembers.map((member, i) => {
      const { name, imageUrl } = memberWrapper(member);
      const memberId = member.siteMemberId;
      const updating = membersUpdate && membersUpdate.includes(memberId);
      return (
        <div
          className={st(classes.memberCardWrapper, { mobile })}
          key={member.siteMemberId}
        >
          <MemberCard
            onSelect={(selected) => this.handleMemberSelect(memberId, selected)}
            allowSelect={true}
            selected={this.isMemberSelected(memberId)}
            name={name.nick || t('groups-web.member.anonymous')}
            image={imageUrl}
            withDivider={!mobile && i !== siteMembers.length - 1}
            updating={updating}
          />
        </div>
      );
    });
  }

  private renderLoader(request: keyof MembersPublicApi) {
    const { requestState } = this.props;
    if (requestState && requestState[request] === RequestState.PENDING) {
      return <PendingRequest requestState={requestState} request={request} />;
    }
    return null;
  }

  private renderInvites() {
    const { t } = this.props;
    const { invitesMap, searchQuery } = this.state;
    return (
      <MemberCard
        name={this.state.searchQuery}
        image={<LetterIcon className={classes.inviteIcon} />}
        done={!!invitesMap[searchQuery.trim()]}
        onActionClick={this.inviteMemberByEmail}
        actionLabel={t('groups-web.invite')}
        actionDoneLabel={t('groups-web.invited')}
      />
    );
  }

  private renderNoSiteMembersState() {
    const { t, group } = this.props;
    const { searchQuery } = this.state;
    const isSecret = isGroupSecret(group);
    let key = '';

    if (isSecret) {
      key = searchQuery
        ? 'groups-web.secret-group.members.empty-search-match.caption'
        : 'groups-web.discussion.members.empty';
    } else {
      key = searchQuery
        ? 'group-web.members.empty-search-match.caption'
        : 'group-web.members.empty.caption';
    }

    return (
      <EmptyState
        transparent
        title={searchQuery ? null : t('group-web.members.empty')}
        content={t(key)}
        boxProps={{
          withTopBottomBorders: false,
          withSideBorders: false,
        }}
      />
    );
  }

  private getNonGroupMembers() {
    const { siteMembers } = this.props;
    const { searchQuery } = this.state;
    return siteMembers.filter((m) => {
      return (
        m.name &&
        m.name.nick &&
        m.name.nick
          .toLocaleLowerCase()
          .includes(searchQuery.trim().toLocaleLowerCase())
      );
    });
  }
}

const enhance = compose(
  translate(),
  withSiteMembers,
  withBiLogger,
  withAppData,
  withInviteMembers,
  withTpaComponentsConfig,
  withExperiments,
);

export const AddMembersModal = enhance(
  AddMembersModalComponent,
) as React.ComponentType<AddMembersModalProps>;
