import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import classname from 'classnames';
import { Box, TextButton } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  canInviteMembers,
  canSeeGroup,
} from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { Text } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../Context/withSiteMembers';
import { MembersList } from './MembersList';
import { MembersWidgetCTAButton } from './MembersWidgetCTAButton';
import { getMembersLabel } from '../../../MembersLabel/MembersLabel';

import discussionStyles from '../Discussion.st.css';
import { classes } from './MembersWidget.st.css';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { GroupApps } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupApps';
import { WithGroup, WithGroupProps } from '../../Context/GroupContext';

class MembersWidgetComponent extends React.Component<
  InjectedTranslateProps &
    WithGroupProps &
    WithSiteMembers &
    InjectedExperimentsProps
> {
  groupApps: GroupApps;
  constructor(props) {
    super(props);

    this.groupApps = GroupApps.fromAppsMap(props.apps);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apps !== this.props.apps) {
      this.groupApps = GroupApps.fromAppsMap(this.props.apps);
    }
  }

  static displayName = 'MembersWidgetComponent';

  render() {
    const { group, isLoggedIn } = this.props;
    if (!canSeeGroup(group)) {
      return null;
    }

    if (!this.groupApps.isMembersTabInstalled()) {
      return null;
    }

    const membersTitle = this.getMembersWidgetTitle();

    return (
      <Box article className={classes.root} data-hook="members-widget">
        <Text
          className={classname(
            discussionStyles.classes.widgetHeader,
            classes.header,
          )}
        >
          {membersTitle}
        </Text>

        {isLoggedIn ? <MembersList /> : this.renderLoginText()}

        <div className={classes.ctaButtonWrapper}>
          {isLoggedIn ? <MembersWidgetCTAButton /> : this.renderLoginButton()}
        </div>
      </Box>
    );
  }

  getMembersWidgetTitle = () => {
    const { t, group } = this.props;
    const { key, value } = getMembersLabel(group, t);
    const addMembersTitle = t(`${key}.add-widget.title`, {
      membersLabel: value,
    });
    return canInviteMembers(group) ? addMembersTitle : value;
  };

  private renderLoginText() {
    return (
      <Text className={classes.description}>
        {this.props.t('groups-web.members-widget.log-in-to-view')}
      </Text>
    );
  }

  private renderLoginButton() {
    const { t, promptLogin } = this.props;
    return (
      <TextButton
        priority={TEXT_BUTTON_PRIORITY.primary}
        onClick={() => promptLogin()}
      >
        {t('groups-web.members-widget.log-in')}
      </TextButton>
    );
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withExperiments,
);

export const MembersWidget = enhance(
  MembersWidgetComponent,
) as React.ComponentType;
