import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';

import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import {
  Spinner,
  TextButton,
} from '@wix/social-groups-common/dist/src/components';

import { RichContentViewer } from '../../RichContent/lazy';
import { PRESETS } from '../../RichContent/types';
import { EmptyState } from '../EmptyState';

import { classes } from './CustomTab.st.css';
import { Origins } from '@wix/social-groups-common/dist/src/bi-logger/customTab/origins';

interface ViewerProps {
  handleEditClick?(biOrigin?: string);

  contentState;
  canEdit: boolean;
}

const _Viewer = (props: ViewerProps & InjectedTranslateProps) => {
  const { handleEditClick, contentState, canEdit, t } = props;
  const onEmptyClick = React.useCallback(() => {
    handleEditClick && handleEditClick(Origins.EMPTY_STATE);
  }, [handleEditClick]);

  const onEditClick = React.useCallback(() => {
    handleEditClick && handleEditClick(Origins.SITE_EDIT); // TODO: no BI event for edit click?
  }, [handleEditClick]);

  if (!contentState) {
    return (
      <EmptyState
        title={t('groups-web.custom-tab.empty.title')}
        content={t('groups-web.custom-tab.empty.content')}
        boxProps={{
          withSideBorders: false,
          withTopBottomBorders: false,
        }}
        button={
          canEdit
            ? {
                label: t('groups-web.custom-tab.empty.button'),
                onClick: onEmptyClick,
              }
            : null
        }
      />
    );
  }

  return (
    <div className={classes.root}>
      <React.Suspense fallback={<Spinner />}>
        <RichContentViewer
          disablePreview
          className={classes.ricos}
          content={contentState}
          preset={PRESETS.CUSTOM_TAB}
        />
        {canEdit && (
          <div className={classes.actions}>
            <TextButton
              priority={TEXT_BUTTON_PRIORITY.primary}
              onClick={onEditClick}
            >
              {t('groups-web.custom-tab.edit')}
            </TextButton>
          </div>
        )}
      </React.Suspense>
    </div>
  );
};

_Viewer.displayName = 'CustomTabViewer';

export const Viewer = translate()(_Viewer) as React.ComponentType<ViewerProps>;
