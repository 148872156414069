import React from 'react';
import { GroupContext } from '../../Group/Context/GroupContext';

export function usePolls(props) {
  const { handleUserValidationRequest } = props;

  const { members } = React.useContext(GroupContext);

  return {
    getSiteMembers: () => members,
    validateUser: handleUserValidationRequest ? validateUser : null,
  };

  function validateUser() {
    return new Promise(handleUserValidationRequest);
  }
}
