import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { TextField } from 'wix-ui-tpa/TextField';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { Tag, SIZE } from 'wix-ui-tpa/Tag';
import { Popover, TriggerAction } from 'wix-ui-tpa/Popover';

import { isAdmin } from '@wix/social-groups-api';
import BiContext from '@wix/social-groups-common/dist/src/context/bi-logger/BiContext';
import { BIUserEntry } from '@wix/social-groups-common/dist/src/context/bi-logger/types';

import { st, classes } from './FeedTopics.st.css';
import { AddIcon } from '../../../icons/AddIcon';
import { GroupContext } from '../../Context/GroupContext';

export interface FeedTopicsProps {
  topicId: string;
  feedItemId?: string;
  isEditable?: boolean;

  onTopicChange(topicId: string);
}

const FeedTopicsComponent: React.FC<
  FeedTopicsProps & InjectedTranslateProps
> = (props) => {
  const { topicId, feedItemId, onTopicChange, isEditable, t } = props;
  const { feed, group } = React.useContext(GroupContext);
  const biLogger = React.useContext(BiContext);
  const topics = feed.feedTopics;

  const canCreateTopics = React.useRef(isAdmin(group));
  const [search, setSearch] = React.useState<string>();
  const [inProgress, setInProgress] = React.useState(false);
  const [shown, setShown] = React.useState(false);

  const filtered = React.useMemo(filter, [search, topics.length]);
  const topic = React.useMemo(findById, [topicId, topics.length]);

  if (topic) {
    return (
      <Tag
        isRemovable={isEditable}
        onRemove={isEditable ? () => clearTopic(topic) : undefined}
      >
        {topic.displayName}
      </Tag>
    );
  }

  return (
    <Popover
      placement="top-start"
      className={st(classes.root)}
      triggerAction={TriggerAction.click}
      shown={shown}
    >
      <Popover.Element>
        <TextButton
          onClick={() => setShown(!shown)}
          priority={TEXT_BUTTON_PRIORITY.secondary}
          prefixIcon={<AddIcon width="24px" height="24px" />}
        >
          {t('groups-web.discussion.topics.modal.open-button')}
        </TextButton>
      </Popover.Element>
      <Popover.Content>
        <div className={st(classes.content)}>
          <TextField
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder={t(
              'groups-web.discussion.topics.modal.search.placeholder',
            )}
            maxLength={40}
          />
          <Text
            tagName="p"
            className={st(classes.label)}
            typography={TYPOGRAPHY.runningText}
          >
            {t(getStatusLabel())}
          </Text>
          <ul className={st(classes.topicList)}>
            {filtered.map((_topic) => (
              <Tag
                key={_topic.id}
                className={st(classes.topic)}
                size={SIZE.small}
                onClick={() => selectTopic(_topic)}
              >
                {`${_topic.displayName} (${_topic.count})`}
              </Tag>
            ))}
            {canCreateTopics.current && search && (
              <li className={st(classes.topic)}>
                <TextButton
                  disabled={inProgress}
                  priority={TEXT_BUTTON_PRIORITY.secondary}
                  onClick={() => createTopic(search)}
                  prefixIcon={<AddIcon width="24px" height="24px" />}
                >
                  {t('groups-web.discussion.topics.modal.create-button')}
                </TextButton>
                &nbsp;&nbsp;
                <Tag size={SIZE.small}>{search}</Tag>
              </li>
            )}
          </ul>
        </div>
      </Popover.Content>
    </Popover>
  );

  function getStatusLabel() {
    if (!topics.length) {
      return canCreateTopics.current
        ? `groups-web.discussion.topics.modal.status-label.empty.admin`
        : 'groups-web.discussion.topics.modal.status-label.empty.member';
    }

    if (!filtered.length) {
      return 'groups-web.discussion.topics.modal.status-label.filters-empty';
    }

    return canCreateTopics.current
      ? 'groups-web.discussion.topics.modal.status-label.admin'
      : 'groups-web.discussion.topics.modal.status-label.member';
  }

  async function createTopic(topicName) {
    setInProgress(true);
    await feed.createPostTopic(topicName);
    setInProgress(false);

    biLogger.groupFeedTopicsAddTopicClickIntent({
      origin: 'topics_list_plus_btn_in_post',
      groupId: group.groupId,
      userEntry: BIUserEntry.SITE,
    });
  }

  function clearTopic(topic) {
    handleSelect(null);

    biLogger.groupFeedTopicsTopicActions({
      action: 'delete',
      origin: feedItemId ? 'post_creation' : 'post_to_the_topic',
      topicName: topic.displayName,
      topicId: topic.id,
      groupId: group.groupId,
      userEntry: BIUserEntry.SITE,
    });
  }

  function selectTopic(topic) {
    handleSelect(topic.id);

    if (feedItemId) {
      biLogger.groupFeedTopicsTopicActions({
        action: 'edit',
        origin: 'post_to_the_topic',
        topicName: topic.displayName,
        topicId: topic.id,
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
      });
    } else {
      biLogger.groupFeedTopicsAddTopicClickIntent({
        origin: 'topics_list_in_post',
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
      });
    }
  }

  function handleSelect(_topicId) {
    onTopicChange(_topicId);
    setSearch(undefined);
  }

  function filter() {
    const pattern = new RegExp(search, 'i');
    return topics.filter((_topic) => pattern.test(_topic.displayName));
  }

  function findById() {
    return topics.find((_topic) => _topic.id === topicId);
  }
};

export const FeedTopics = translate()(FeedTopicsComponent) as any;
