import React, { ComponentType, FC } from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import { Spinner, TextButton } from '@wix/social-groups-common/dist/src/components';

import { LayoutProps } from './types';

import { classes, st } from '../CustomTab.st.css';

const _MobileLayout: FC<LayoutProps & InjectedTranslateProps> = props => {
  const { isLoading, children, onCancel, onSubmit, t } = props;

  return (
    <div className={st(classes.root, { mobile: true })}>
      <div className={classes.header}>
        <TextButton
          onClick={onCancel}
          priority={TEXT_BUTTON_PRIORITY.secondary}
        >
          {t('groups-web.custom-tab.cancel')}
        </TextButton>
        <TextButton
          onClick={onSubmit}
          priority={TEXT_BUTTON_PRIORITY.primary}
          disabled={isLoading}
        >
          {t('groups-web.custom-tab.save')}
        </TextButton>
      </div>
      <React.Suspense fallback={<Spinner />}>
        {children}
      </React.Suspense>
    </div>
  );
};

export const MobileLayout = translate()(_MobileLayout) as ComponentType<LayoutProps>;
