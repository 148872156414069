import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
// WIX-UI-TPA
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
// COMMON
import {
  Block,
  BlockAlign,
  BlockFlow,
  Box,
  Header,
  TextButton,
} from '@wix/social-groups-common/dist/src/components';

import { ContentConverter } from '@wix/social-groups-common/dist/src/components/ContentEditor/content/ContentConverter';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { RawDraftContentState } from '@wix/social-groups-common/dist/src/components/ContentEditor/types';
import {
  BIUserEntry,
  MemberInvites,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
  withMemberInvites,
} from '@wix/social-groups-common/dist/src/context';
import { compose } from '@wix/social-groups-common/dist/src/compose';
// API
import { canEdit } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { GroupDTO } from '@wix/social-groups-api/dist/src/model/Group/GroupDTO';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';

import { GroupUpdate, withGroupUpdate } from '../Context/GroupUpdate';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../Context/withTpaComponentsConfig';
// STYLES
import { classes, st } from './About.st.css';
import { RichContentViewer } from '../../RichContent/Viewer';
import { PRESETS } from '../../RichContent/types';
import { AboutEditor } from './AboutEditor';

export const ABOUT_GROUP = 'about-group';

interface AboutProps {
  group: ApiTypes.v1.GroupResponse;
  updateProgress: UpdateProgress;
}

type Props = AboutProps &
  InjectedTranslateProps &
  GroupUpdate &
  WithTpaComponentsConfigProps &
  WithBiLoggerProps &
  MemberInvites;

interface AboutComponentState {
  editing: boolean;
}

class AboutComponent extends React.Component<Props, AboutComponentState> {
  static displayName = 'AboutComponent';
  state: AboutComponentState = {
    editing: false,
  };
  editMode = () => {
    const { biLogger, group } = this.props;
    this.setState({ editing: true });
    tryToCallBi(async () => {
      await biLogger.groupsAboutEditClicked({
        groupId: group.groupId,
        origin: 'edit_descr_link',
        userEntry: BIUserEntry.SITE,
      });
    });
    this.props.setEditMode(UpdateProgress.STARTED);
  };

  viewMode = () => {
    this.setState({ editing: false });
    this.props.setEditMode(UpdateProgress.STALE);
  };

  componentDidCatch(error, info) {
    console.error(error, info);
  }

  render() {
    const { t, group, mobile } = this.props;
    const titleLabel = t('groups-web.about');

    const editing = this.editing();
    const editButton =
      canEdit(group) && !editing ? (
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={this.editMode}
        >
          {t('groups-web.viewer.about.edit')}
        </TextButton>
      ) : null;
    return (
      <Block align={BlockAlign.start} flow={BlockFlow.row}>
        <Box
          withTopBottomBorders={!mobile}
          withSideBorders={!mobile}
          className={st(classes.aboutRoot, { mobile })}
        >
          <div className={classes.headerWrapper}>
            <Header className={classes.header} data-hook={ABOUT_GROUP}>
              {titleLabel}
            </Header>
            {editButton}
          </div>
          {editing ? this.renderEditor() : this.renderViewer()}
        </Box>
      </Block>
    );
  }

  private editing() {
    return this.state.editing;
  }

  private readonly updateAbout = (contentString: string) => {
    const { biLogger, group } = this.props;
    const g = new GroupDTO(this.props.group);
    const text = ContentConverter.parseText(contentString);
    tryToCallBi(async () => {
      await biLogger.groupsAboutEditSave({
        groupId: group.groupId,
        length: text.length,
        origin: 'modal_scrn_save_btn',
        userEntry: BIUserEntry.SITE,
      });
    });
    const { details } = g.setDescription(contentString);
    // TODO: FIX IT!!!!!1111oneoneone
    this.props.updateGroup(g.getUpdatePaths(), details);
    // TODO: fix flaky UX (optimistic update)
    this.viewMode();
  };

  private renderViewer(): React.ReactNode {
    const contentState = this.getRawContentState();
    return (
      <div className={classes.viewer}>
        <RichContentViewer
          disablePreview
          content={contentState}
          preset={PRESETS.ABOUT}
          usage={'About'}
        />
      </div>
    );
  }

  private renderEditor() {
    const { mobile } = this.props;

    return (
      <AboutEditor
        mobile={mobile}
        content={this.getRawContentState()}
        saveChanges={this.updateAbout}
        cancel={this.viewMode}
        loading={this.props.updateProgress === UpdateProgress.UPDATING}
      />
    );
  }

  private getRawContentState(): RawDraftContentState<any> {
    const g = new GroupDTO(this.props.group);
    const description = g.getDescription();
    return ContentConverter.parseContentString(description);
  }
}

const enhance = compose(
  withGroupUpdate,
  translate(),
  withTpaComponentsConfig,
  withMemberInvites,
  withBiLogger,
);

export const About = enhance(AboutComponent) as React.ComponentType<AboutProps>;
About.displayName = 'About';
