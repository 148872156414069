import Experiments from '@wix/wix-experiments';

export function getQueryFromLocation(q: string) {
  if (typeof document === 'undefined') {
    return;
  }
  const params = new URLSearchParams(location.search);
  return params.get(q);
}

export function isNewCommentsEnabled(experiments: Experiments) {
  return experiments.enabled('specs.groups.newComments');
}
