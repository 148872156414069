import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import { EmptyState } from '../../EmptyState';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';

import { classes } from './EmptyStates.st.css';
import { EFilterKeys } from '../../../../controllers/feed/FeedControllerProps';
import { GroupContext } from '../../Context/GroupContext';

interface EmptyStatesProps {
  openNewPostModal(): void;
}

const EmptyStatesComponent: React.FC<
  EmptyStatesProps & InjectedTranslateProps & WithTpaComponentsConfigProps
> = (props) => {
  const { t, openNewPostModal, mobile } = props;
  const {
    feed: { feedFilters, applyFeedFilters },
  } = React.useContext(GroupContext);
  const emptyFilters = !Object.values(feedFilters).some((value) => !!value);

  // No posts yet
  if (emptyFilters) {
    return (
      <EmptyState
        className={classes.root}
        title={t('groups-web.discussion.first.title')}
        content={t('groups-web.discussion.first.description')}
        button={{
          label: t('groups-web.discussion.first.button'),
          onClick: openNewPostModal,
        }}
        boxProps={{
          withSideBorders: !mobile,
        }}
      />
    );
  }

  // Post by id not found
  if (feedFilters[EFilterKeys.ID]) {
    return (
      <EmptyState
        className={classes.root}
        title={t('groups-web.discussion.post-not-found.title')}
        content={t('groups-web.discussion.post-not-found.subtitle')}
        button={{
          label: t('groups-web.discussion.post-not-found.back-to-discussion'),
          onClick: resetFilters,
        }}
        boxProps={{
          withSideBorders: !mobile,
        }}
      />
    );
  }

  // Filter results empty
  return (
    <EmptyState
      className={classes.root}
      title={t('groups-web.discussion.filters.empty-result.title')}
      content={t('groups-web.discussion.filters.empty-result.subtitle')}
      button={{
        label: t('groups-web.discussion.filters.empty-result.button'),
        onClick: openNewPostModal,
      }}
      boxProps={{
        withSideBorders: !mobile,
      }}
    />
  );

  function resetFilters() {
    applyFeedFilters();
  }
};

const enhance = compose(translate(), withTpaComponentsConfig);

export const EmptyStates = enhance(
  EmptyStatesComponent,
) as React.ComponentType<EmptyStatesProps>;
