import React, { useContext, useMemo, useState } from 'react';

import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { isAdmin } from '@wix/social-groups-api';
import { Box } from '@wix/social-groups-common/dist/src/components';

import { GroupContext } from '../Context/GroupContext';
import { GroupActionsContext } from '../Context/GroupActionsContext';

import { Viewer } from './Viewer';
import { Editor } from './Editor';

import { classes } from './CustomTab.st.css';
import BiContext from '@wix/social-groups-common/dist/src/context/bi-logger/BiContext';
import { BIUserEntry } from '@wix/social-groups-common/dist/src/context/bi-logger/types';
import {
  addCustomTabContentBI,
  customTabSaveBI,
} from '@wix/social-groups-common/dist/src/bi-logger/customTab/customTabBI';
import { Tab } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupApps';

export const CustomTab = () => {
  const { apps, group } = useContext(GroupContext);
  const { configureApps } = useContext(GroupActionsContext);
  const [isEditMode, setIsEditMode] = useState(false);
  const biLogger = React.useContext(BiContext);
  const customTabApp = apps[ApiTypes.v1.GroupAppKey.CUSTOM_APP];
  const canEdit = useMemo(() => isAdmin(group), [group.relationship]);
  const contentState = useMemo(() => {
    try {
      return JSON.parse(customTabApp.customSource);
    } catch (error) {
      return null;
    }
  }, [customTabApp.customSource]);

  return (
    <Box article>
      <SwitchTransition>
        <CSSTransition
          timeout={300}
          classNames={classes as CSSTransitionClassNames}
          key={isEditMode ? 'editor' : 'viewer'}
        >
          {isEditMode ? (
            <Editor
              contentState={contentState}
              handleCancelClick={toggleViewMode}
              handleSubmitClick={handleSubmit}
            />
          ) : (
            <Viewer
              canEdit={canEdit}
              contentState={contentState}
              handleEditClick={toggleEditMode}
            />
          )}
        </CSSTransition>
      </SwitchTransition>
    </Box>
  );

  function handleSubmit(_contentState) {
    customTabSaveBI(
      biLogger,
      {
        groupId: group.groupId,
        userEntry: BIUserEntry.SITE,
        tabName: customTabApp.customName || Tab.CUSTOM,
      },
      _contentState,
    );
    return configureApps([
      {
        installed: true,
        key: customTabApp.key,
        customName: customTabApp.customName,
        customSource: JSON.stringify(_contentState),
      },
    ]);
  }

  function toggleEditMode(origin) {
    setIsEditMode(true);
    addCustomTabContentBI(biLogger, {
      origin,
      groupId: group.groupId,
      userEntry: BIUserEntry.SITE,
    });
  }

  function toggleViewMode() {
    setIsEditMode(false);
  }
};

CustomTab.displayName = 'CustomTab';
