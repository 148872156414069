import { pluginGiphy } from 'wix-rich-content-plugin-giphy/dist/module.viewer.cjs';
import { pluginImage } from 'wix-rich-content-plugin-image/dist/module.viewer.cjs';
import { pluginEmoji } from 'wix-rich-content-plugin-emoji/dist/module.viewer.cjs';
import { pluginMentions } from 'wix-rich-content-plugin-mentions/dist/module.viewer.cjs';
import { pluginFileUpload } from 'wix-rich-content-plugin-file-upload/dist/module.viewer.cjs';
import { pluginLink } from 'wix-rich-content-plugin-link/dist/module.viewer.cjs';
import { pluginLinkPreview } from 'wix-rich-content-plugin-link-preview/dist/module.viewer.cjs';
import { PluginTypes, PRESETS } from '../types';
import { AsyncPluginSelector } from './asyncPlugins/AsyncPluginSelector';

const viewerPluginMap = {
  [PluginTypes.Image]: pluginImage,
  [PluginTypes.Giphy]: pluginGiphy,
  [PluginTypes.FileUpload]: pluginFileUpload,
  [PluginTypes.Emoji]: pluginEmoji,
  [PluginTypes.Mention]: pluginMentions,
  [PluginTypes.ExternalMentions]: pluginMentions,
  [PluginTypes.ExternalLink]: pluginLink,
  [PluginTypes.LinkPreview]: pluginLinkPreview,
};

export const viewerPluginSelector = (preset: PRESETS, helpers) =>
  AsyncPluginSelector.getInstance(viewerPluginMap, preset, helpers);
