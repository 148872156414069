import richContentEditorTheme from './rich-content-editor.theme.st.css';
import pluginLinkTheme from './plugin-link.theme.st.css';
import textLinkTheme from './text-link.theme.st.css';
import linkViewerTheme from './link-viewer.theme.st.css';
import hashtagTheme from './text-hashtag.theme.st.css';
import resizableTheme from './resizable.st.css';

import dividerTheme from './divider.theme.st.css';
import htmlTheme from './html.theme.st.css';
import imageTheme from './image.theme.st.css';
import videoTheme from './video.theme.st.css';
import fileUploadTheme from './file-upload.theme.st.css';

import commonTheme from './global.theme.st.css';
import checkboxTheme from './checkbox.theme.st.css';
import loaderTheme from './loader.theme.st.css';
import mentionsTheme from './mentions-plugin.theme.st.css';
import dropdownTheme from './dropdown.theme.st.css';
import emojiTheme from './emoji-preview.theme.st.css';
import buttonTheme from './button.theme.st.css';
import imageComponentTheme from './image_component.theme.st.css';
import inputWithLabelTheme from './input-with-label.theme.st.css';
import radioGroupHorizontalTheme from './radio-group-horizontal.theme.st.css';
import radioGroupTheme from './radio-group.theme.st.css';
import settingsPanelFooterTheme from './settings-panel-footer.theme.st.css';
import settingsSectionTheme from './settings-section.theme.st.css';
import selectionListTheme from './selection-list.theme.st.css';
import tabsTheme from './tabs.theme.st.css';
import tooltipTheme from './tooltip.theme.st.css';
import placeholderTheme from './placeholder.theme.st.css';
import sliderTheme from './slider.theme.st.css';
import labeledToggleTheme from './labeled-toggle.st.css';

import inlineToolbarTheme from './toolbars/inline-toolbar.theme.st.css';
import textStaticToolbarTheme from './toolbars/text-static-toolbar.theme.st.css';
import sideToolbarTheme from './toolbars/side-toolbar.theme.st.css';
import pluginToolbarTheme from './toolbars/plugin-toolbar.theme.st.css';
import footerToolbarTheme from './toolbars/footer-toolbar.theme.st.css';
import mobileToolbarTheme from './toolbars/mobile-toolbar.theme.st.css';
import mobileAddModalTheme from './toolbars/mobile-add-modal.theme.st.css';
import toolbarSeparatorTheme from './toolbars/toolbar-separator.theme.st.css';
import addPluginModalTheme from './toolbars/add-plugin-modal.theme.st.css';
import videoUploadTheme from './toolbars/modals/video-upload-modal.theme.st.css';
import gallerySettingsTheme from './toolbars/modals/gallery-settings.theme.st.css';
import interactionsTheme from './interactions.st.css';

import pollPresetSelectorTheme from './poll-preset-selector.st.css';
import pollSettingsTheme from './poll-settings.theme.st.css';

export const theme = [
  richContentEditorTheme,
  textLinkTheme,
  pluginLinkTheme,
  linkViewerTheme,
  hashtagTheme,
  resizableTheme,
  dividerTheme,
  htmlTheme,
  imageTheme,
  videoTheme,
  fileUploadTheme,
  commonTheme,
  checkboxTheme,
  emojiTheme,
  dropdownTheme,
  buttonTheme,
  loaderTheme,
  mentionsTheme,
  imageComponentTheme,
  inputWithLabelTheme,
  radioGroupHorizontalTheme,
  radioGroupTheme,
  settingsPanelFooterTheme,
  settingsSectionTheme,
  selectionListTheme,
  tabsTheme,
  tooltipTheme,
  placeholderTheme,
  sliderTheme,
  labeledToggleTheme,
  videoUploadTheme,
  gallerySettingsTheme,
  addPluginModalTheme,
  inlineToolbarTheme,
  textStaticToolbarTheme,
  sideToolbarTheme,
  pluginToolbarTheme,
  footerToolbarTheme,
  mobileToolbarTheme,
  mobileAddModalTheme,
  toolbarSeparatorTheme,
  interactionsTheme,
  pollPresetSelectorTheme,
  pollSettingsTheme,
].reduce(
  (acc, styles: any) => {
    return {
      ...acc,
      ...styles.classes,
    };
  },
  {
    modalTheme: {
      content: {},
      overlay: {
        // 'top', 'right', 'bottom', 'left' are rendered as single 'inset' for browsers that support it
        // If 'inset' is used it is rendered as these four values for browsers that don't support it
        // However inset: '0' is rendered in Safari as "top: 0, left: 'auto', right: 0, bottom: auto" and plugins modal is not visible
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        width: 'var(--siteRootWidth, initial)',
        margin: '0 auto',
      },
    },
  },
);
