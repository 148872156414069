import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  Box,
  Header,
  InfoCard,
  InfoCardProps,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { canSeeGroup } from '@wix/social-groups-api/dist/src/model/';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../Context/withTpaComponentsConfig';
import classnames from 'classnames';
import { withActivity, WithActivityProps } from '../../Context/withActivity';
import { NewMembersProps, withNewMembers } from '../../Context/withNewMembers';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../../Context/withAppSettings';
import { getMembersLabel } from '../../../MembersLabel/MembersLabel';
import moment from 'moment';

import activityStyles from './GroupActivity.st.css';
import { st, classes } from '../About.st.css';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';

interface GroupProps {
  group: ApiTypes.v1.GroupResponse;
}

export interface GroupActivityProps
  extends InjectedTranslateProps,
    WithActivityProps,
    GroupProps,
    NewMembersProps,
    WithTpaComponentsConfigProps,
    WithAppSettingsProps {
  group: ApiTypes.v1.GroupResponse;
  className?: string;
}

const GroupActivityCard: React.FC<InfoCardProps> = ({
  title,
  subtitle,
  withPlaceholder,
}) => (
  <div className={activityStyles.classes.activityWrapper}>
    <InfoCard
      className={activityStyles.classes.activity}
      title={title}
      subtitle={subtitle}
      withPlaceholder={withPlaceholder}
      reverse
    />
  </div>
);

const GroupActivityDivider = () => (
  <div className={activityStyles.classes.divider}></div>
);

class GroupActivityComponent extends React.Component<GroupActivityProps> {
  componentDidMount(): void {
    if (canSeeGroup(this.props.group)) {
      this.getActivityStats();
    }
  }

  componentDidUpdate(prevProps: Readonly<GroupActivityProps>) {
    if (!canSeeGroup(prevProps.group) && canSeeGroup(this.props.group)) {
      this.getActivityStats();
    }
  }

  // TODO: call this when real reactions data will be provided
  renderReactionsStats() {
    const { t } = this.props;
    return (
      <>
        <GroupActivityDivider />
        <GroupActivityCard
          title={'42'}
          subtitle={t('groups-web.activity.reactions')}
        />
      </>
    );
  }

  render() {
    const {
      t,
      activities,
      group,
      newMembers,
      mobile,
      appSettings,
    } = this.props;
    if (!canSeeGroup(group)) {
      return null;
    }
    const newPosts = activities && activities.newPosts;
    const membersLabel = getMembersLabel(group, t, newMembers);
    const showActivityKey = getSettingsKeyFor('showActivity', mobile);
    if (!appSettings[showActivityKey]) {
      return null;
    }

    return (
      <Box className={st(classes.root)}>
        <div
          className={classnames(
            classes.headerWrapper,
            mobile ? '' : activityStyles.classes.headerWrapper,
          )}
        >
          <Header className={classes.header}>
            {t('groups-web.activity')}:
            <span className={classes.faded}>
              {' '}
              {t('groups-web.lastNDays', { N: 30 })}
            </span>
          </Header>
        </div>
        <div
          className={activityStyles.st(
            activityStyles.classes.activities,
            { mobile },
            this.props.className,
          )}
        >
          <GroupActivityCard
            title={this.isActivityValueFetched(newPosts) && `${newPosts}`}
            subtitle={t('groups-web.activity.posts', { count: newPosts })}
            withPlaceholder
          />
          <GroupActivityDivider />
          <GroupActivityCard
            title={this.isActivityValueFetched(newMembers) && `${newMembers}`}
            subtitle={t('groups-web.activity.members', {
              membersLabel: membersLabel.value,
              count: newMembers,
            })}
            withPlaceholder
          />
        </div>
      </Box>
    );
  }

  private isActivityValueFetched(value) {
    return value !== null && value >= 0;
  }

  private getActivityStats() {
    try {
      const { activityActions, getNewMembers, group } = this.props;
      activityActions.getActivity(group);
      getNewMembers(group, moment().subtract(1, 'months').toDate());
    } catch (e) {
      console.log('Get group activity: FAIL');
    }
  }
}

const enhance = compose(
  withNewMembers,
  withActivity,
  withTpaComponentsConfig,
  withAppSettings,
  translate(),
);

export const GroupActivity: React.ComponentType<GroupProps> = enhance(
  GroupActivityComponent,
);
