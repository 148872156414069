import React from 'react';

import { InjectedTranslateProps, translate } from 'react-i18next';
import {
  canJoinGroup,
  isAdmin,
  isGroupPrivate,
  isJoined,
} from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '@wix/social-groups-common/dist/src/components/Button';
import { compose } from '@wix/social-groups-common/dist/src/compose';

import { DATA_HOOKS } from './dataHooks';

import { withProfile, WithProfileProps } from '../Context/Profile/withProfile';
import { UpdateProgress } from '@wix/social-groups-common/dist/src/components/ContentEditor/UpdateProgress';
import { Button as ButtonType } from '../../../controllers/group/button';
import { classes } from './MembershipButton.st.css';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import {
  MembershipChangeAction,
  withMembershipChangeAction,
} from '../Context/MembershipAction';
import { withAppData, WithAppDataProps } from '../Context/AppData/withAppData';

interface MembershipButtonConfig {
  buttonLabelKey: string;
  priority: PRIORITY;

  onClick?(): void;
}

const getMembershipButtonPreferences = (
  group: ApiTypes.v1.GroupResponse,
): MembershipButtonConfig => {
  const joinLabel =
    isAdmin(group) || !isGroupPrivate(group)
      ? 'groups-web.join'
      : 'groups-web.request-to-join';

  const config: {
    [key in ApiTypes.v1.RelationshipWithGroup]: MembershipButtonConfig;
  } = {
    [ApiTypes.v1.RelationshipWithGroup.NONE]: {
      buttonLabelKey: joinLabel,
      priority: PRIORITY.primary,
    },
    [ApiTypes.v1.RelationshipWithGroup.PENDING_APPROVAL]: {
      buttonLabelKey: 'groups-web.pending',
      priority: PRIORITY.secondary,
    },
    [ApiTypes.v1.RelationshipWithGroup.JOINED]: {
      buttonLabelKey: 'groups-web.joined',
      priority: PRIORITY.primary,
    },
    [ApiTypes.v1.RelationshipWithGroup.REJECTED_MEMBERSHIP]: {
      buttonLabelKey: joinLabel,
      priority: PRIORITY.primary,
    },
  };
  return config[group.relationship];
};

export interface MembershipButtonProps {
  className?: string;
  biOrigin?: string;
}

type Props = WithGroupProps &
  InjectedTranslateProps &
  WithProfileProps &
  MembershipChangeAction &
  MembershipButtonProps &
  WithAppDataProps;

interface State {
  shouldChangeMembership: boolean;
}

class MembershipButtonComponent extends React.Component<Props, State> {
  state: State = {
    shouldChangeMembership: false,
  };
  private privacyChanged: boolean;

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
  ): void {
    const privacyChanged =
      prevProps.isProfilePrivate && !this.props.isProfilePrivate;
    if (!this.privacyChanged && privacyChanged) {
      this.privacyChanged = privacyChanged;
    }

    const groupUpdated =
      prevProps.updateProgress === UpdateProgress.STARTED &&
      this.props.updateProgress === UpdateProgress.STALE;

    if (
      this.privacyChanged &&
      groupUpdated &&
      !isJoined(this.props.group) &&
      this.state.shouldChangeMembership
    ) {
      this.handleClick(null);
      this.privacyChanged = false;
    }
  }

  shouldRender() {
    const { isEditor, viewMode, isMobile, group, activeButton } = this.props;

    if (isEditor) {
      if (isMobile) {
        return false;
      }
      if (viewMode === 'Preview') {
        return false;
      }

      return !isMobile && activeButton === ButtonType.PRIMARY;
    }

    if (!canJoinGroup(group)) {
      return false;
    }

    if (isJoined(group)) {
      return false;
    }

    return true;
  }

  render() {
    const { t, group, isProfileUpdating, updateProgress } = this.props;

    if (!this.shouldRender()) {
      return null;
    }

    const { buttonLabelKey, priority } = getMembershipButtonPreferences(group);
    const disabled =
      isProfileUpdating || updateProgress !== UpdateProgress.STALE;
    return (
      <div className={classes.root}>
        <Button
          fullWidth
          priority={priority}
          onClick={this.handleClick}
          data-hook={DATA_HOOKS.membershipButton}
          disabled={disabled}
        >
          {t(buttonLabelKey)}
        </Button>
      </div>
    );
  }

  private readonly handleClick = (e) => {
    e && e.stopPropagation();
    if (this.props.isProfilePrivate) {
      this.props.openProfileDialog();
      this.setState({ shouldChangeMembership: true });
      return;
    }
    const { biOrigin, changeMembership } = this.props;
    changeMembership(biOrigin);
    this.setState({ shouldChangeMembership: false });
  };
}

const enhance = compose(
  translate(),
  WithGroup,
  withProfile,
  withMembershipChangeAction,
  withAppData,
);

export const MembershipButton: React.FunctionComponent<MembershipButtonProps> = enhance(
  MembershipButtonComponent,
);

MembershipButton.displayName = 'MembershipButton';
