import React from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { EmptyState } from '../EmptyState';

const EventsEmptyStateComponent: React.FC<InjectedTranslateProps> = ({ t }) => {
  return (
    <EmptyState
      title={t('groups-web.events.empty.title')}
      content={t('groups-web.events.empty.subtitle')}
    />
  );
};

export const EventsEmptyState = translate()(
  EventsEmptyStateComponent,
) as React.ComponentType;
