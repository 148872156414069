import React from 'react';
import { IWithStylesProps } from '@wix/native-components-infra/dist/src/HOC/withStyles/withStyles';
import { Button } from '../../../../controllers/group/button';
import { AppData } from './IAppData';

export type AppDataWithStyles = AppData & Partial<IWithStylesProps>;
export const AppDataContext = React.createContext<AppDataWithStyles>({
  host: undefined,
  isRTL: false,
  style: undefined,
  appDefinitionId: '',
  instance: '',
  language: undefined,
  locale: undefined,
  instanceId: undefined,
  isEditor: false,
  viewMode: 'Site',
  activeButton: Button.PRIMARY,
});
AppDataContext.displayName = 'AppDataContext';
