import React, { FC, ComponentType } from 'react';
import { translate, InjectedTranslateProps } from 'react-i18next';

import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button, Loader, Spinner } from '@wix/social-groups-common';

import { LayoutProps } from './types';

import { classes } from '../CustomTab.st.css';

const _DesktopLayout: FC<LayoutProps & InjectedTranslateProps> = props => {
  const { isLoading, children, onCancel, onSubmit, t } = props;

  return (
    <div className={classes.root}>
      <React.Suspense fallback={<Spinner />}>
        {children}
        <div className={classes.actions}>
          <Button
            priority={PRIORITY.basicSecondary}
            onClick={onCancel}
          >
            {t('groups-web.custom-tab.cancel')}
          </Button>
          <Button
            className={classes.submit}
            priority={PRIORITY.basic}
            onClick={onSubmit}
            disabled={isLoading}
            prefixIcon={isLoading && <Loader />}
          >
            {t('groups-web.custom-tab.save')}
          </Button>
        </div>
      </React.Suspense>
    </div>
  );
}

export const DesktopLayout = translate()(_DesktopLayout) as ComponentType<LayoutProps>;
