import React from 'react';
import { MembersQueryInitialResponse } from '@wix/social-groups-api/dist/src/model/Member/MembersQueryResponse';
import { SiteMembers } from '../../../controllers/members/SiteMembers';
import { PendingMembers } from '../../../controllers/members/PendingMembers';
import { MembersActionsType } from '../../../controllers/members/MembersActions';

export interface SiteMembersWithActions extends SiteMembers, PendingMembers {
  membersActions: MembersActionsType;
}
export const SiteMembersContext = React.createContext<SiteMembersWithActions>({
  membersUpdate: undefined,
  currentSiteMember: undefined,
  followingMembers: [],
  membersActions: undefined,
  membersQueryResponse: MembersQueryInitialResponse,
  newMembers: undefined,
  pendingMembers: undefined,
  siteMembers: [],
  siteMembersMap: {},
  nonGroupMembersCount: undefined,
  questionsAnswers: {},
  requestState: {},
});

SiteMembersContext.displayName = 'SiteMembersContext';
