import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { Text } from 'wix-ui-tpa/Text';
import { RoleBadges } from '@wix/social-groups-common/dist/src/components/RoleBadges';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { classes, st } from './Details.st.css';
import { MembersCount } from '../MembersCount';
import { getSettingsKeyFor } from '@wix/social-groups-common/dist/src/utils/utils';
import { withTPAConfig } from '@wix/social-groups-common/dist/src/components/withTPAConfig';
import { TPAComponentsConfig } from 'wix-ui-tpa/TPAComponentsConfig';
import { DETAILS, DETAILS_TITLE } from './dataHooks';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';
import {
  withAppSettings,
  WithAppSettingsProps,
} from '../Context/withAppSettings';

// TODO: extract shared translations
export const DetailsComponent: React.FC<
  WithGroupProps &
    InjectedTranslateProps &
    TPAComponentsConfig &
    WithAppSettingsProps
> = ({ mobile, group, t, appSettings }) => {
  const {
    details,
    relationship,
    roles,
    settings: { privacyLevel },
  } = group;

  const showMembersCountKey = getSettingsKeyFor('showMembersCount', mobile);

  const showMembersCount = appSettings[showMembersCountKey];

  return (
    <div data-hook={DETAILS} className={st(classes.root, { mobile })}>
      <div className={classes.titleAndRole}>
        <Text tagName="h1" data-hook={DETAILS_TITLE} className={classes.title}>
          {details.title}
        </Text>
        {!mobile && (
          <>
            <div className={classes.titleAndRoleDelimiter} />
            <RoleBadges relationship={relationship} roles={roles} />
          </>
        )}
      </div>
      <div className={classes.privacyAndMembers}>
        {mobile && roles.length > 0 && (
          <>
            <RoleBadges relationship={relationship} roles={roles} />
            <div className={classes.titleAndRoleDelimiter} />
          </>
        )}
        <Text className={classes.groupInfoItem}>
          {t(`groups-web.types.${privacyLevel.toLowerCase()}.name`)}
        </Text>
        {showMembersCount && (
          <>
            <Text className={classes.groupInfoItem}>&nbsp;·&nbsp;</Text>
            <MembersCount className={classes.groupInfoItem} />
          </>
        )}
      </div>
    </div>
  );
};
const enhance = compose(translate(), WithGroup, withAppSettings, withTPAConfig);

export const Details = enhance(DetailsComponent);
