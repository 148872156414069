import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';
import { Text } from 'wix-ui-tpa/Text';

import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { GroupAppsMap } from '@wix/social-groups-api/dist/src/model/GroupApps/GroupAppsMap';

import { Box, TextButton } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { ContentConverter } from '@wix/social-groups-common/dist/src/components/ContentEditor/content/ContentConverter';

import { classes } from './About.st.css';
import discussionStyles from '../Discussion.st.css';
import {
  WithGroupActionProps,
  WithGroupActions,
} from '../../Context/GroupActionsContext';

interface AboutComponentProps {
  group?: ApiTypes.v1.GroupResponse;
  apps: GroupAppsMap;
}

export interface AboutProps extends WithGroupActionProps, AboutComponentProps {}

type Props = AboutProps & InjectedTranslateProps & WithBiLoggerProps;
export const AboutComponent: React.FC<Props> = ({
  t,
  group,
  changeTab,
  biLogger,
  apps,
}) => {
  const descr = ContentConverter.parseText(group.details.description);

  const onReadMore = () => {
    tryToCallBi(async () => {
      await biLogger.groupsTabClicked({
        group_id: group.groupId,
        origin: 'pinned_window',
        name: 'about',
      });
    });

    changeTab('about');
  };

  const readMore =
    descr && descr.length > 60 ? (
      <>
        ...
        <TextButton
          onClick={onReadMore}
          priority={TEXT_BUTTON_PRIORITY.primary}
          className={classes.readMore}
        >
          {t('groups-web.more')}
        </TextButton>
      </>
    ) : null;
  return (
    <Box article data-hook="about-widget">
      <Text className={discussionStyles.classes.widgetHeader}>
        {getAboutTabName(apps) || t('groups-web.discussion.about.title')}
      </Text>
      <Text className={classes.description}>
        {(descr && descr.substr(0, 60)) ||
          t('groups-web.discussion.about.description')}
        {readMore}
      </Text>
    </Box>
  );
};

function getAboutTabName(apps: GroupAppsMap) {
  try {
    const { customName } = apps[ApiTypes.v1.GroupAppKey.ABOUT_APP] || null;
    return customName;
  } catch (e) {}
}

const enhance = compose(translate(), WithGroupActions, withBiLogger);

export const About = enhance(
  AboutComponent,
) as React.ComponentType<AboutComponentProps>;
