/**
 * Combines two arrays of type T into one array with @max items
 * @param {T[]} arr1
 * @param {T[]} arr2
 * @param {number} max
 * @return {T[]}
 */
export function combineArrays<T>(arr1: T[], arr2: T[], max: number): T[] {
  const end = Math.min(Math.max(max - arr1.length, 0), max);
  return arr1.slice(0, max).concat(arr2.slice(0, end));
}

/**
 * flattens 2 a dimensional array
 * @param {any[][]} arr
 * @return {any[]}
 */
export function flatten(arr: any[][]) {
  if (!Array.prototype.flat) {
    // IE
    return [].concat(...arr);
  }
  return arr.flat();
}

export function isEmptyObject(o: object) {
  return !Object.keys(o).length;
}
