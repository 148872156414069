import { IFeedItem } from './store/types';
import { Anonymous } from '@wix/social-groups-api';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import { SiteMembers } from '../members/SiteMembers';

export const getPostAuthor = (
  feedItem: IFeedItem,
  siteMembersMap: SiteMembers['siteMembersMap'],
): ApiTypes.v1.GroupMemberResponse =>
  siteMembersMap[feedItem.createdBy] || Anonymous;
