import React from 'react';
import { SiteMembers } from '../../../controllers/members/SiteMembers';
import { WixSiteMemberActions } from '@wix/social-groups-common/dist/src/context/members/WixSiteMembers';
import { MembersActions } from '../../../controllers/members/MembersActions';
import { PendingMembers } from '../../../controllers/members/PendingMembers';
import { Questions } from '../../../controllers/members/Questions';
import { SiteMembersContext, SiteMembersWithActions } from './SiteMembers';

export interface WithSiteMembers
  extends MembersActions,
    WixSiteMemberActions,
    SiteMembers,
    PendingMembers,
    Questions {}

export const withSiteMembers = <P extends WithSiteMembers>(
  WrappedComponent: React.ComponentType<P>,
) => (props) => {
  return (
    <SiteMembersContext.Consumer>
      {({ membersActions, ...restProps }: SiteMembersWithActions) => (
        <WrappedComponent {...props} {...membersActions} {...restProps} />
      )}
    </SiteMembersContext.Consumer>
  );
};
