import React from 'react';

import { InjectedTranslateProps, translate } from 'react-i18next';
import classname from 'classnames';
import { AvatarSize } from 'wix-ui-tpa/Avatar';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';
import { TEXT_BUTTON_PRIORITY } from 'wix-ui-tpa/TextButton';

import {
  TextButton,
  UserCard,
} from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import { GroupMember } from '@wix/social-groups-api/dist/src/model/Member/GroupMember';
import { canInviteMembers } from '@wix/social-groups-api/dist/src/model/Member/permissions';
import { isPendingGroup } from '@wix/social-groups-api/dist/src/model/Group/GroupPrivacy';
import { MembersRequestBuilder } from '@wix/social-groups-api/dist/src/model/Member/MembersRequestBuilder';
import { ApiTypes } from '@wix/social-groups-api/dist/src/types';
import {
  withSiteMembers,
  WithSiteMembers,
} from '../../Context/withSiteMembers';
import {
  BIUserEntry,
  tryToCallBi,
  withBiLogger,
  WithBiLoggerProps,
} from '@wix/social-groups-common/dist/src/context';
import { CanNotAddMembersModal } from '../../modals/CanNotAddMemebersModal';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';

import { classes } from './MembersWidget.st.css';
import { combineArrays } from '../../../../utils/utils';
import { WithGroup, WithGroupProps } from '../../Context/GroupContext';

interface MembersListState {
  isModalOpen: boolean;
}

type Props = InjectedTranslateProps &
  WithGroupProps &
  WithSiteMembers &
  WithBiLoggerProps &
  InjectedExperimentsProps;

const MEMBERS_QUERY_LIMIT = 5;

export class MembersListComponent extends React.Component<
  Props,
  MembersListState
> {
  static displayName = 'MembersListComponent';
  state = {
    isModalOpen: false,
  };

  componentDidMount() {
    this.getFollowingMembers();
    this.setNonGroupMembers();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!prevProps.currentSiteMember && this.props.currentSiteMember) {
      this.getFollowingMembers();
    }
    if (!prevProps.setNonGroupMembers && this.props.setNonGroupMembers) {
      this.setNonGroupMembers();
    }
  }

  handleMemberAddClick(siteMemberId: string) {
    const { biLogger, group } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.groupsAddMemberClicked({
          groupId: group.groupId,
          origin: 'widget_plus_add_btn',
          userEntry: BIUserEntry.SITE,
        });
      });
      if (isPendingGroup(group)) {
        this.openModal();
      } else {
        this.props.addMembers(group, [siteMemberId]);
      }
    };
  }

  handleMemberFollowClick(siteMemberId: string) {
    const { biLogger, group } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.followMember({
          group_id: group.groupId,
          site_member_id: siteMemberId,
          origin: 'discussion_tab_link_clk',
          userEntry: BIUserEntry.SITE,
          action: 'follow',
        });
      });

      this.props.followMember(siteMemberId);
    };
  }

  handleMemberUnfollowClick(siteMemberId: string) {
    const { biLogger, group } = this.props;
    return () => {
      tryToCallBi(async () => {
        await biLogger.followMember({
          group_id: group.groupId,
          site_member_id: siteMemberId,
          origin: 'discussion_tab_link_clk',
          userEntry: BIUserEntry.SITE,
          action: 'unfollow',
        });
      });

      this.props.unfollowMember(siteMemberId);
    };
  }

  navigateToUserProfile(siteMemberId: string) {
    return () => this.props.openUserProfile(siteMemberId);
  }

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { t, followingMembers, group, membersUpdate } = this.props;

    const { isModalOpen } = this.state;
    const canInvite = canInviteMembers(group);
    const members = this.getMembers(canInvite);
    if (!members.length) {
      return this.renderEmptyState();
    }
    // Array.prototype.includes() is not supported in IE 🤷‍ + Set.prototype.has() has better performance
    const following = new Set(followingMembers);
    const updating = new Set(membersUpdate);
    return (
      <>
        {members.map((m) => {
          const siteMemberId = m.getId();
          const isFollowing = following.has(siteMemberId);

          const addOrFollowLabel = this.getAddOrFollowLabel(
            canInvite,
            m.relatedToGroup(),
            isFollowing,
          );
          return (
            <div className={classes.memberWrapper} key={siteMemberId}>
              <UserCard
                className={classes.userCard}
                name={m.getName() || t('groups-web.member.anonymous')}
                pic={m.getImageUrl()}
                avatarSize={AvatarSize.small}
                onUserClick={this.navigateToUserProfile(siteMemberId)}
                userAction={
                  <TextButton
                    onClick={this.AddOrFollowMember(
                      canInvite,
                      siteMemberId,
                      isFollowing,
                    )}
                    priority={TEXT_BUTTON_PRIORITY.primary}
                    disabled={updating.has(siteMemberId)}
                  >
                    {addOrFollowLabel}
                  </TextButton>
                }
              />
            </div>
          );
        })}
        <CanNotAddMembersModal isOpen={isModalOpen} onClose={this.closeModal} />
      </>
    );
  }

  private setNonGroupMembers() {
    if (this.props.setNonGroupMembers && canInviteMembers(this.props.group)) {
      const q = new MembersRequestBuilder()
        .addPaging(MEMBERS_QUERY_LIMIT)
        .build();
      this.props.setNonGroupMembers(q);
    }
  }

  private getFollowingMembers() {
    this.props.currentSiteMember &&
      this.props.getFollowingMembers(this.props.currentSiteMember.id);
  }

  private getAddOrFollowLabel(canInvite, isGroupMember, isFollowing) {
    const key =
      canInvite && !isGroupMember
        ? 'groups-web.add'
        : isFollowing
        ? 'groups-web.following'
        : 'groups-web.follow';

    return this.props.t(key);
  }

  private getMembers(canInvite: boolean): GroupMember[] {
    const { members, siteMembers, currentMember, membersUpdate } = this.props;
    if (!members.length && !siteMembers.length) {
      return [];
    }
    const updating = new Set(membersUpdate);
    const me = new GroupMember(currentMember);
    const iAmInGroup = me.relatedToGroup();
    const groupMembers = iAmInGroup
      ? members.filter((m) => !me.isMe(m))
      : members;
    let otherMembers: ApiTypes.v1.GroupMemberResponse[] = groupMembers.slice(
      0,
      MEMBERS_QUERY_LIMIT,
    );

    if (canInvite) {
      const nonGroup = siteMembers
        .slice(0, MEMBERS_QUERY_LIMIT)
        .filter((m) => !updating.has(m.siteMemberId));
      otherMembers = combineArrays(nonGroup, otherMembers, MEMBERS_QUERY_LIMIT);
    }
    return otherMembers.map((m) => new GroupMember(m));
  }

  private renderEmptyState() {
    return (
      <Text
        tagName="p"
        typography={TYPOGRAPHY.runningText}
        className={classname(classes.description, classes.empty)}
      >
        {this.props.t('groups-web.discussion.members.empty')}
      </Text>
    );
  }

  private AddOrFollowMember(
    canInvite: boolean,
    siteMemberId: string,
    isFollowing: boolean,
  ) {
    // TODO: refactor this for canInvite but nobody to invite (i.e. all members are invited)
    return canInvite
      ? this.handleMemberAddClick(siteMemberId)
      : isFollowing
      ? this.handleMemberUnfollowClick(siteMemberId)
      : this.handleMemberFollowClick(siteMemberId);
  }
}

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withBiLogger,
  withExperiments,
);

export const MembersList = enhance(MembersListComponent);
