import { useMemo } from 'react';

import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';
import { RicosTheme } from '@wix/ricos-common/dist/types';
import { getWixColor } from '@wix/tpa-settings';

import { theme as cssOverride } from './theme';

export function useRicosTheme({ id, style}: IHostProps, useCssOverride) {
  const { siteColors, styleParams } = style;
  const { postsTextColor, postsBackgroundColor } = styleParams.colors;
  const { postsTextFont } = styleParams.fonts;

  const theme = useMemo<RicosTheme>(() => {
    return {
      parentClass: id,
      paletteConfig: {
        contentBgColor: false,
      },
      typography: {
        fontFamily: postsTextFont?.family,
      },
      palette: {
        textColor: postsTextColor?.value || getColor('color-5'),
        bgColor: postsBackgroundColor?.value || getColor('color-1'),
        actionColor: getColor('color-8'),
        disabledColor: getColor('color-3'),
        fallbackColor: getColor('color-5'),
        textColorLow: getColor('color-5'),
      },
    };
  }, [postsTextColor, postsBackgroundColor, postsTextFont, id]);

  if (useCssOverride) {
    return {
      cssOverride,
    }
  }

  return { theme };

  function getColor(reference) {
    return getWixColor({ colors: siteColors, reference })?.value;
  }
}
