import React from 'react';
import { InjectedTranslateProps, translate } from 'react-i18next';
import { PRIORITY } from 'wix-ui-tpa/Button';
import { Button } from '@wix/social-groups-common/dist/src/components';
import { compose } from '@wix/social-groups-common/dist/src/compose';
import {
  InjectedExperimentsProps,
  withExperiments,
} from '@wix/wix-experiments-react';
import { WithSiteMembers, withSiteMembers } from '../Context/withSiteMembers';
import { WithGroup, WithGroupProps } from '../Context/GroupContext';

interface FollowButtonComponentProps {
  siteMemberId: string;
}

type FollowButtonProps = FollowButtonComponentProps &
  WithSiteMembers &
  InjectedExperimentsProps &
  InjectedTranslateProps &
  WithGroupProps;

const FollowButtonComponent: React.FC<FollowButtonProps> = (props) => {
  const {
    followingMembers,
    currentMember,
    experiments,
    t,
    openUserProfile,
    unfollowMember,
    followMember,
    siteMemberId,
  } = props;

  const isFollowing = followingMembers.includes(siteMemberId);
  const isCurrentUser = currentMember.siteMemberId === siteMemberId;

  return (
    <Button
      priority={
        isCurrentUser || isFollowing ? PRIORITY.secondary : PRIORITY.primary
      }
      onClick={(e) => {
        e.stopPropagation();

        if (isCurrentUser) {
          openUserProfile(siteMemberId);
          return;
        }

        if (isFollowing) {
          unfollowMember(siteMemberId);
          return;
        }

        followMember(siteMemberId);
      }}
    >
      {isCurrentUser
        ? t('groups-web.my-profile')
        : isFollowing
        ? t('groups-web.following')
        : t('groups-web.follow')}
    </Button>
  );
};

const enhance = compose(
  translate(),
  WithGroup,
  withSiteMembers,
  withExperiments,
);

export const FollowButton = enhance(
  FollowButtonComponent,
) as React.ComponentType<FollowButtonComponentProps>;
